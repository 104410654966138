import { Input } from '@tigergraph/app-ui-lib/input';
import { FormControl } from 'baseui/form-control';
import { Button } from '@tigergraph/app-ui-lib/button';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import 'particles.js';
import fotlogin from '../../assets/fotlogin.svg';
import errorsvg from '../../assets/error.svg';
import { CenterContainer, ExtraLargeSpinner, StyledContainer, StyledFormContainer } from './styled';
import { useParticleJS } from './hook';
import { ChangePasswordRequest, Return, changePasswordReq } from './api';
import { useStyletron } from '@tigergraph/app-ui-lib/Theme';
import { expand } from 'inline-style-expand-shorthand';
import showToast from '../../components/showToast';
import { KIND } from 'baseui/toast';
import { getErrorMessage, getHashSearchStr } from '../../utils/utils';

export default function ChangePassword() {
  const [css, theme] = useStyletron();
  useParticleJS();

  const [username, setUserName] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const { isLoading, mutate: changePassword } = useMutation<Return<void>, AxiosError, ChangePasswordRequest>(
    'change-password',
    changePasswordReq
  );

  function handlFormChange(type: string) {
    return (e) => {
      switch (type) {
        case 'username':
          setUserName(e.target.value);
          break;
        case 'password':
          setCurrentPassword(e.target.value);
          break;
        case 'newPassword':
          setNewPassword(e.target.value);
          break;
        case 'confirmPassword':
          setConfirmPassword(e.target.value);
          break;
        default:
          break;
      }
    };
  }

  const handleSubmit = () => {
    if (newPassword !== confirmPassword) {
      setErrorMsg('Passwords do not match');
      return;
    }

    changePassword(
      { name: username, currentPassword, password: newPassword },
      {
        onError(err) {
          if (err.response?.status === 401) {
            setErrorMsg('Your username or current password is incorrect.');
          } else {
            setErrorMsg(getErrorMessage(err));
          }
        },
        onSuccess() {
          showToast({ kind: KIND.positive, message: 'Password changed successfully' });

          const searchStr = getHashSearchStr();
          window.location.href = `/#/login${searchStr ? '?' + searchStr : ''}`;
        },
      }
    );
  };

  useEffect(() => {
    setErrorMsg('');
  }, [username, currentPassword, newPassword, confirmPassword]);

  return (
    <StyledContainer id="particles-js">
      <StyledFormContainer>
        <div
          className={css({
            paddingBottom: '24px',
            borderBottom: '1px solid rgba(212, 218, 223, 0.4)',
          })}
        >
          <CenterContainer>
            <img
              className={css({
                marginLeft: 'auto',
                marginRight: 'auto',
              })}
              src={fotlogin}
              alt=""
            />
          </CenterContainer>
        </div>
        <div>
          <FormControl
            label="Username"
            caption=""
            overrides={{
              Label: {
                style: () => ({
                  color: '#222222',
                  ...theme.typography.Body2,
                }),
              },
            }}
          >
            <Input
              value={username}
              onChange={handlFormChange('username')}
              overrides={{
                Root: {
                  style: {
                    ...expand({ borderColor: '#AAB5BF' }),
                  },
                },
              }}
            />
          </FormControl>
        </div>
        <div>
          <FormControl
            label="Current Password"
            caption=""
            overrides={{
              Label: {
                style: {
                  ...theme.typography.Body2,
                  color: '#222222',
                },
              },
            }}
          >
            <Input
              type="password"
              value={currentPassword}
              onChange={handlFormChange('password')}
              overrides={{
                Root: {
                  style: {
                    ...expand({ borderColor: '#AAB5BF' }),
                  },
                },
              }}
              // onKeyDown={onInputKeyDown}
            />
          </FormControl>
        </div>
        <div>
          <FormControl
            label="New Password"
            caption=""
            overrides={{
              Label: {
                style: () => ({
                  color: '#222222',
                  ...theme.typography.Body2,
                }),
              },
            }}
          >
            <Input
              type="password"
              value={newPassword}
              onChange={handlFormChange('newPassword')}
              overrides={{
                Root: {
                  style: {
                    ...expand({ borderColor: '#AAB5BF' }),
                  },
                },
              }}
            />
          </FormControl>
        </div>
        <div>
          <FormControl
            label="Confirm Password"
            caption=""
            overrides={{
              Label: {
                style: () => ({
                  color: '#222222',
                  ...theme.typography.Body2,
                }),
              },
            }}
          >
            <Input
              type="password"
              value={confirmPassword}
              onChange={handlFormChange('confirmPassword')}
              overrides={{
                Root: {
                  style: {
                    ...expand({ borderColor: '#AAB5BF' }),
                  },
                },
              }}
            />
          </FormControl>
        </div>
        <div
          className={css({
            display: 'flex',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '12px',
            color: '#B93535',
            minHeight: '16px',
            marginTop: '-2px',
            marginBottom: '10px',
          })}
        >
          {!!errorMsg && (
            <>
              <img className={css({ marginRight: '2px' })} src={errorsvg} alt="" />
              {errorMsg}
            </>
          )}
        </div>
        <div
          className={css({
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          })}
        >
          <CenterContainer>
            <Button
              onClick={handleSubmit}
              type="submit"
              disabled={!username || !currentPassword || !newPassword || !confirmPassword || isLoading}
              overrides={{
                BaseButton: {
                  style: () => ({
                    backgroundColor: '#FB9A44',
                    width: '360px',
                    '@media screen and (max-width: 720px)': {
                      width: '100%',
                    },
                    height: '40px',
                    color: '#222222',
                    borderRadius: '5px',
                    marginTop: '10px',
                  }),
                },
              }}
            >
              {isLoading ? (
                <>
                  <ExtraLargeSpinner />
                </>
              ) : (
                'Change Password'
              )}
            </Button>
          </CenterContainer>
        </div>
      </StyledFormContainer>
    </StyledContainer>
  );
}
